div[class*="CalendarMonth_caption"] {
    /* margin-left: 10px;
    padding-bottom: 0px;
    margin-bottom: 37px; */
    font-family: "Capitolium-Bold";
    font-size: 18px;
    color: #4c515b;
}

div[class*="DateRangePicker_picker"] {
    top: 60px !important;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.22)
}

div[class*="DayPicker__horizontal"],
div[class*="CalendarMonth"] {
    background-color: #fff;
}

div[class*="DayPicker_weekHeader DayPicker_weekHeader_1"] {
    margin-top: 12px;
    font-family: "Roboto-Medium";
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
}

div[class*="DayPicker_transitionContainer DayPicker_transitionContainer_1 DayPicker_transitionContainer__horizontal DayPicker_transitionContainer__horizontal_2"] {
    height: 350px !important;
}

table[class*="CalendarMonth_table CalendarMonth_table_1"] {
    margin-top: 20px;
}

td[class*="CalendarDay__default"] {
    border: none;
    font-family: "Roboto";
    /* color: rgba(0, 0, 0, 0.87) !important; */
    font-weight: 400;
    font-size: 12px;
    /* background-color: #ffffff !important; */
}
td[class*="CalendarDay__default"]:hover {
    border: none;
    /* border-radius: 100% !important; */
    /* background-color: #C6E5F7; */
}

/* td[class*="CalendarDay__selected_start"] {
    border: none;
    border-radius: 50% 0 0 50%;
    background-color: #C6E5F7;
    color: #ffffff;
    border-radius: inherit;
    position: relative;
    z-index: 2;
} */

/* td[class*="CalendarDay__selected"] {
    border: none;
    border-radius: 100% !important;
    background: #75C3F3;
    color: #ffffff;
}
td[class*="CalendarDay__selected"]:hover {
    background: linear-gradient(-90deg, #0077B6 0%, #00527F 100%);
    color: #ffffff;
    border-radius: 100% !important;
}

td[class*="CalendarDay__selected_start"] {
    border: none !important;
    border-radius: 50% 0 0 50% !important;
    background-color: #C6E5F7 !important;
    color: #ffffff !important;
    border-radius: inherit;
    position: relative;
    z-index: 2;
}

td[class*="CalendarDay__selected_start"]:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(-90deg, #0077B6 0%, #00527F 100%);
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

td[class*="CalendarDay__selected_end"] {
    border: none !important;
    border-radius: 0 50% 50% 0 !important;
    background-color: #C6E5F7 !important;
    color: #ffffff !important;
    border-radius: inherit;
    position: relative;
    z-index: 2;
}
td[class*="CalendarDay__selected_end"]:hover {
    background: linear-gradient(-90deg, #0077B6 0%, #00527F 100%);
    border-radius: none;
}

td[class*="CalendarDay__selected_end"]:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(-90deg, #0077B6 0%, #00527F 100%);
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 100%;
}

td[class*="CalendarDay__selected_span"] {
    border: none;
    border-radius: 0 !important;
    background-color: #C6E5F7;
    color: #000;
}
td[class*=" CalendarDay__selected_span CalendarDay__lastDayOfWeek"] {
    border: none;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: #C6E5F7;
    color: #000;
} */

td[class*="CalendarDay__blocked_out_of_range"],
td[class*="CalendarDay__blocked_out_of_range"]:hover,
td[class*="CalendarDay__blocked_out_of_range"]:active {
    color: #d0d0d0;
    background: none;
}

/* td[class*="CalendarDay__today"] {
    color: #006BA6;
    background: none;
    position: relative;
} */

/* td[class*="CalendarDay__today"]:before {
    content: ".";
    font-size: 35px;
    color: inherit;
    position: absolute;
    bottom: 6px;
    left: 14.8px;
} */

/* td[class*="CalendarDay__hovered_span"],
td[class*="CalendarDay__hovered_span"]:hover {
    border: none;
    color: #382942;
    border-radius: 0 !important;
    background-color: #C6E5F7 !important;
}

td[class*="CalendarDay__blocked_calendar"] {
    color: #d0d0d0 !important;
    background: none !important;
} */

/* table[class*="CalendarMonth_table CalendarMonth_table_1"] tr td:first-child {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

table[class*="CalendarMonth_table CalendarMonth_table_1"] tr td:last-child {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
} */

button[class*="DayPickerKeyboardShortcuts_show"] {
    display: none;
}