@font-face {
    font-family: 'roboto-thin';
    font-style: normal;
    font-weight: 100;
    src: url(./Roboto-Thin.ttf);
}

@font-face {
    font-family: 'roboto-thin-italic';
    font-style: normal;
    font-weight: 100;
    src: url(./Roboto-ThinItalic.ttf);
}

@font-face {
    font-family: 'roboto-light';
    font-style: normal;
    font-weight: 300;
    src: url(./Roboto-Light.ttf);
}

@font-face {
    font-family: 'roboto-light-italic';
    font-style: normal;
    font-weight: 300;
    src: url(./Roboto-LightItalic.ttf);
}

@font-face {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto-Regular.ttf);
}

@font-face {
    font-family: 'roboto-italic';
    font-style: normal;
    font-weight: 400;
    src: url(./Roboto-Italic.ttf);
}

@font-face {
    font-family: 'roboto-medium';
    font-style: normal;
    font-weight: 500;
    src: url(./Roboto-Medium.ttf);
}

@font-face {
    font-family: 'roboto-medium-italic';
    font-style: normal;
    font-weight: 500;
    src: url(./Roboto-MediumItalic.ttf);
}

@font-face {
    font-family: 'roboto-bold';
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto-Bold.ttf);
}

@font-face {
    font-family: 'roboto-bold-italic';
    font-style: normal;
    font-weight: 700;
    src: url(./Roboto-BoldItalic.ttf);
}

@font-face {
    font-family: 'roboto-black';
    font-style: normal;
    font-weight: 900;
    src: url(./Roboto-Black.ttf);
}

@font-face {
    font-family: 'roboto-black-italic';
    font-style: normal;
    font-weight: 900;
    src: url(./Roboto-BlackItalic.ttf);
}