@font-face {
    font-family: 'plus-jakarta-sans-extrabold';
    font-style: normal;
    font-weight: 800;
    src: url(./PlusJakartaSans-ExtraBold.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-extrabold-italic';
    font-style: italic;
    font-weight: 800;
    src: url(./PlusJakartaSans-ExtraBoldItalic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-bold';
    font-style: normal;
    font-weight: 700;
    src: url(./PlusJakartaSans-Bold.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-bold-italic';
    font-style: italic;
    font-weight: 700;
    src: url(./PlusJakartaSans-BoldItalic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-semibold';
    font-style: normal;
    font-weight: 600;
    src: url(./PlusJakartaSans-SemiBold.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-semibold-italic';
    font-style: italic;
    font-weight: 600;
    src: url(./PlusJakartaSans-SemiBoldItalic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-medium';
    font-style: normal;
    font-weight: 500;
    src: url(./PlusJakartaSans-Medium.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-medium-italic';
    font-style: italic;
    font-weight: 500;
    src: url(./PlusJakartaSans-MediumItalic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-regular';
    font-style: normal;
    font-weight: 400;
    src: url(./PlusJakartaSans-Regular.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-regular-italic';
    font-style: italic;
    font-weight: 400;
    src: url(./PlusJakartaSans-Italic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-light';
    font-style: normal;
    font-weight: 300;
    src: url(./PlusJakartaSans-Light.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-light-italic';
    font-style: italic;
    font-weight: 300;
    src: url(./PlusJakartaSans-LightItalic.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-extralight';
    font-style: normal;
    font-weight: 200;
    src: url(./PlusJakartaSans-ExtraLight.ttf);
}

@font-face {
    font-family: 'plus-jakarta-sans-extralight-italic';
    font-style: italic;
    font-weight: 200;
    src: url(./PlusJakartaSans-ExtraLightItalic.ttf);
}